import React, {useEffect} from "react"
import { navigate } from "gatsby";



const NotFoundPage = () => {
  useEffect(() => {
    navigate("/"); // redirecting to home page
  }, []);
  return(
    <>Not found</>
  )
}

export default NotFoundPage
